<template>
  <div>
    <div v-if="stillLoading">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-show="!stillLoading">
      <b-progress v-show="showProgress" :value="counterProgress" :max="100" show-progress animated></b-progress>
      <b-col sm="12" tag="div">
        <b>
          <div slot="header">
            <strong style="color: black">
              {{ $route.meta.label }}

              <small> - Form </small>
            </strong>
          </div>
          <b-row tag="div">
            <b-col sm="12" tag="div">
              <b-form-group breakpoint="sm" v-for="item in columns" v-bind:key="item.field">
                <label for="name" style="color: black">
                  {{ item.label }}
                </label>
                <img :src="options[item.field]" v-if="item.field == 'url'" />

                <b-form-input
                  v-else-if="item.field == 'name'"
                  :placeholder="'Enter ' + item.label + ' ...'"
                  type="text"
                  v-model="options[item.field]"
                  :disabled="item.field == 'id' || item.field == 'created_date' || item.field == 'updated_date'"
                />
                <div v-else-if="item.field == 'description'">
                  <quill-editor v-model="options[item.field]" />
                </div>
                <b-form-group v-else-if="item.field == 'answer_detail'">
                  <b-button variant="primary" @click="submitData" v-if="($store.getters['customer/customer'].role_id = 1)">
                    <i class="fa fa-dot-circle-o" />
                    Submit
                  </b-button>
                  <div v-for="(itemAnswerDetail, indexAnswerDetail) in options[item.field]">
                    <b-button variant="warning" @click="deleteQuestion(indexAnswerDetail)">
                      <i class="fa fa-minus-square" />
                    </b-button>
                    <label style="color: black"> Question {{ indexAnswerDetail + 1 }} </label>
                    <div>
                      <label style="color: black"> No </label>
                      <b-form-input :placeholder="'Enter No ...'" type="text" v-model="itemAnswerDetail.no" />
                    </div>
                    <div>
                      <label style="color: black"> Question </label>
                      <quill-editor v-model="itemAnswerDetail.question" />
                      <br />
                    </div>
                    <div>
                      <label style="color: black"> A </label>
                      <quill-editor v-model="itemAnswerDetail.a" />
                      <br />
                    </div>
                    <div>
                      <label style="color: black"> B </label>
                      <quill-editor v-model="itemAnswerDetail.b" />
                      <br />
                    </div>
                    <div>
                      <label style="color: black"> C </label>
                      <quill-editor v-model="itemAnswerDetail.c" />
                      <br />
                    </div>
                    <div>
                      <label style="color: black"> D </label>
                      <quill-editor v-model="itemAnswerDetail.d" />
                      <br />
                    </div>
                    <div>
                      <label style="color: black"> E </label>
                      <quill-editor v-model="itemAnswerDetail.e" />
                      <br />
                    </div>
                    <div>
                      <label style="color: black"> Answer True </label>
                      <select v-model="itemAnswerDetail.answer_true" class="form-control m-input">
                        <option value="a" selected="selected">A</option>
                        <option value="b" selected="selected">B</option>
                        <option value="c" selected="selected">C</option>
                        <option value="d" selected="selected">D</option>
                        <option value="e" selected="selected">E</option>
                      </select>
                    </div>
                  </div>
                  <b-button variant="primary" @click="addQuestion" style="margin-left: 10px"> <i class="fa fa-plus-square" />Tambah Question </b-button>
                </b-form-group>

                <select v-else-if="item.field == 'answer_type_id'" v-model="options[item.field]" class="form-control m-input" :placeholder="'Enter ' + item.label + ' ...'">
                  <option v-for="(item_answer, index_answer) in list_answer_type" v-bind:key="item_answer.id" :value="item_answer.id">{{ item_answer.name }}</option>
                </select>
                <select v-else-if="item.field == 'instructur_id'" v-model="options[item.field]" class="form-control m-input" :placeholder="'Enter ' + item.label + ' ...'">
                  <option value="0" selected="selected">ID Instructur</option>
                  <option v-for="(item_instructur, index_instructur) in list_instructur" v-bind:key="item_instructur.id" :value="item_instructur.id">{{ item_instructur.name }}</option>
                </select>
                <b-form-input
                  v-else
                  :placeholder="'Enter ' + item.label + ' ...'"
                  type="text"
                  v-model="options[item.field]"
                  :disabled="item.field == 'id' || item.field == 'created_date' || item.field == 'updated_date'"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-button variant="primary" @click="submitData" v-if="($store.getters['customer/customer'].role_id = 1)">
            <i class="fa fa-dot-circle-o" />
            Submit
          </b-button>
        </b>
      </b-col>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { showVueToast } from '@/utils'
export default {
  name: 'AnswerForm',
  mounted: function () {
    this.modeForm = this.$route.meta.mode
    if (this.modeForm === 'Edit') {
      this.idForm = this.$route.params.id
    }
    this.initData()
  },
  data: function () {
    return {
      options: { id: 0, name: '', instructur_id: 0, answer_type_id: 0, answer_detail: [], description: '' },
      showProgress: true,
      counterProgress: 0,
      idForm: 0,
      modeForm: null,
      columns: [
        { field: 'id', label: 'Id' },
        { field: 'id', label: 'Id' },
        { field: 'absensi_report_id', label: 'Absensi Report Id' },
        { field: 'exercise_id', label: 'Exercise Id' },
        { field: 'answer_type_name', label: 'Answer Type' },
        { field: 'score', label: 'Score' },
        { field: 'answer', label: 'Answer' },
        { field: 'account_id', label: 'Account Id' },
        { field: 'quiz_index', label: 'Quiz Index' }
      ],
      masters: {},
      customer: {},
      list_instructur: [],
      list_answer_type: [
        { id: 1, name: 'Pretest' },
        { id: 2, name: 'Quiz' },
        { id: 3, name: 'Exam' }
      ],
      levelId: 0,
      locked: false,
      stillLoading: true,

      profileLogo: ''
    }
  },
  methods: {
    initData() {
      if (this.$route.meta.name === 'customer' && this.$route.meta.mode === 'Edit') {
        if (this.$route.params.id !== this.$store.getters['customer/customer'].id) {
          this.locked = true
        }
      }
      if (this.locked) {
        return null
      }

      this.counterProgress = 0
      this.customer = this.$store.getters['customer/customer']
      this.getListInstructur()
      if (this.modeForm === 'Edit') {
        this.$store.dispatch('answer/GET_ANSWER', { masterType: this.$route.meta.name, masterId: this.idForm }).then((resp) => {
          if (resp.data.code === 200) {
            let tempData = this.$store.getters['answer/answer']
            for (var key in tempData) {
              this.options[key] = tempData[key]
            }
          } else {
            // this.$swal(resp.data.message)
          }
          this.counterProgress = 100
          this.showProgress = false
          this.stillLoading = false
        })
      } else {
        this.showProgress = false
        this.stillLoading = false
      }
    },
    submitData() {
      if (this.modeForm === 'Edit') {
        if (this.$route.meta.name === 'customer') {
          this.options.logo = this.profileLogo
        }
        this.$store.dispatch('answer/UPDATE_ANSWER', { masterType: this.$route.meta.name, masterId: this.idForm, payload: this.options }).then((resp) => {
          showVueToast('The process is successful!', 'success', 2000)
          if (resp.data.status === '200 OK') {
            this.$router.push('/' + this.$route.meta.name)
          }
          // this.$swal(resp.data.status)
        })
      } else {
        this.$store.dispatch('answer/CREATE_ANSWER', { masterType: this.$route.meta.name, masterId: this.idForm, payload: this.options }).then((resp) => {
          showVueToast('The process is successful!', 'success', 2000)
          if (resp.data.status === '200 OK') {
            if (this.customer.role_id > 2) {
              this.initData()
            } else {
              this.$router.push('/' + this.$route.meta.name)
            }
          }
          // this.$swal(resp.data.status)
        })
      }
    },
    addQuestion: function () {
      this.options.answer_detail.push({ id: 0, answer_id: 0, no: this.options.answer_detail.length + 1, a: '', b: '', c: '', d: '', e: '', question: '', answer_true: '' })
    },
    deleteQuestion: function (indexQuestion) {
      this.options.answer_detail.splice(indexQuestion, 1)
    },

    getListInstructur() {
      let paramsTemp = queryString.stringify({
        ...{
          limit: 100,
          sort: ''
        }
      })
      this.$store
        .dispatch('instructur/GET_LIST_INSTRUCTUR', { masterType: this.$route.meta.name, params: paramsTemp })
        .then(() => {
          this.list_instructur = this.$store.getters['instructur/list_instructur'] ? this.$store.getters['instructur/list_instructur'] : []
        })
        .catch(function () {})
    }
  },
  computed: {
    data: function () {
      return this.$store.getters['answer/list_answer'] ? this.$store.getters['answer/list_answer'] : []
    }
  },
  created: function () {
    //this.initData();
  },
  components: {
    quillEditor
  }
}
</script>
<style scoped>
.ql-editor ol li {
  display: list-item;
}
</style>
