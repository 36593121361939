var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.stillLoading,
      expression: "!stillLoading"
    }]
  }, [_c('b-progress', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showProgress,
      expression: "showProgress"
    }],
    attrs: {
      "value": _vm.counterProgress,
      "max": 100,
      "show-progress": "",
      "animated": ""
    }
  }), _c('b-col', {
    attrs: {
      "sm": "12",
      "tag": "div"
    }
  }, [_c('b', [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('strong', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(" " + _vm._s(_vm.$route.meta.label) + " "), _c('small', [_vm._v(" - Form ")])])]), _c('b-row', {
    attrs: {
      "tag": "div"
    }
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "tag": "div"
    }
  }, _vm._l(_vm.columns, function (item) {
    return _c('b-form-group', {
      key: item.field,
      attrs: {
        "breakpoint": "sm"
      }
    }, [_c('label', {
      staticStyle: {
        "color": "black"
      },
      attrs: {
        "for": "name"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]), item.field == 'url' ? _c('img', {
      attrs: {
        "src": _vm.options[item.field]
      }
    }) : item.field == 'name' ? _c('b-form-input', {
      attrs: {
        "placeholder": 'Enter ' + item.label + ' ...',
        "type": "text",
        "disabled": item.field == 'id' || item.field == 'created_date' || item.field == 'updated_date'
      },
      model: {
        value: _vm.options[item.field],
        callback: function callback($$v) {
          _vm.$set(_vm.options, item.field, $$v);
        },
        expression: "options[item.field]"
      }
    }) : item.field == 'description' ? _c('div', [_c('quill-editor', {
      model: {
        value: _vm.options[item.field],
        callback: function callback($$v) {
          _vm.$set(_vm.options, item.field, $$v);
        },
        expression: "options[item.field]"
      }
    })], 1) : item.field == 'answer_detail' ? _c('b-form-group', [(_vm.$store.getters['customer/customer'].role_id = 1) ? _c('b-button', {
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": _vm.submitData
      }
    }, [_c('i', {
      staticClass: "fa fa-dot-circle-o"
    }), _vm._v(" Submit ")]) : _vm._e(), _vm._l(_vm.options[item.field], function (itemAnswerDetail, indexAnswerDetail) {
      return _c('div', [_c('b-button', {
        attrs: {
          "variant": "warning"
        },
        on: {
          "click": function click($event) {
            return _vm.deleteQuestion(indexAnswerDetail);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-minus-square"
      })]), _c('label', {
        staticStyle: {
          "color": "black"
        }
      }, [_vm._v(" Question " + _vm._s(indexAnswerDetail + 1) + " ")]), _c('div', [_c('label', {
        staticStyle: {
          "color": "black"
        }
      }, [_vm._v(" No ")]), _c('b-form-input', {
        attrs: {
          "placeholder": 'Enter No ...',
          "type": "text"
        },
        model: {
          value: itemAnswerDetail.no,
          callback: function callback($$v) {
            _vm.$set(itemAnswerDetail, "no", $$v);
          },
          expression: "itemAnswerDetail.no"
        }
      })], 1), _c('div', [_c('label', {
        staticStyle: {
          "color": "black"
        }
      }, [_vm._v(" Question ")]), _c('quill-editor', {
        model: {
          value: itemAnswerDetail.question,
          callback: function callback($$v) {
            _vm.$set(itemAnswerDetail, "question", $$v);
          },
          expression: "itemAnswerDetail.question"
        }
      }), _c('br')], 1), _c('div', [_c('label', {
        staticStyle: {
          "color": "black"
        }
      }, [_vm._v(" A ")]), _c('quill-editor', {
        model: {
          value: itemAnswerDetail.a,
          callback: function callback($$v) {
            _vm.$set(itemAnswerDetail, "a", $$v);
          },
          expression: "itemAnswerDetail.a"
        }
      }), _c('br')], 1), _c('div', [_c('label', {
        staticStyle: {
          "color": "black"
        }
      }, [_vm._v(" B ")]), _c('quill-editor', {
        model: {
          value: itemAnswerDetail.b,
          callback: function callback($$v) {
            _vm.$set(itemAnswerDetail, "b", $$v);
          },
          expression: "itemAnswerDetail.b"
        }
      }), _c('br')], 1), _c('div', [_c('label', {
        staticStyle: {
          "color": "black"
        }
      }, [_vm._v(" C ")]), _c('quill-editor', {
        model: {
          value: itemAnswerDetail.c,
          callback: function callback($$v) {
            _vm.$set(itemAnswerDetail, "c", $$v);
          },
          expression: "itemAnswerDetail.c"
        }
      }), _c('br')], 1), _c('div', [_c('label', {
        staticStyle: {
          "color": "black"
        }
      }, [_vm._v(" D ")]), _c('quill-editor', {
        model: {
          value: itemAnswerDetail.d,
          callback: function callback($$v) {
            _vm.$set(itemAnswerDetail, "d", $$v);
          },
          expression: "itemAnswerDetail.d"
        }
      }), _c('br')], 1), _c('div', [_c('label', {
        staticStyle: {
          "color": "black"
        }
      }, [_vm._v(" E ")]), _c('quill-editor', {
        model: {
          value: itemAnswerDetail.e,
          callback: function callback($$v) {
            _vm.$set(itemAnswerDetail, "e", $$v);
          },
          expression: "itemAnswerDetail.e"
        }
      }), _c('br')], 1), _c('div', [_c('label', {
        staticStyle: {
          "color": "black"
        }
      }, [_vm._v(" Answer True ")]), _c('select', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: itemAnswerDetail.answer_true,
          expression: "itemAnswerDetail.answer_true"
        }],
        staticClass: "form-control m-input",
        on: {
          "change": function change($event) {
            var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
              return o.selected;
            }).map(function (o) {
              var val = "_value" in o ? o._value : o.value;
              return val;
            });

            _vm.$set(itemAnswerDetail, "answer_true", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
          }
        }
      }, [_c('option', {
        attrs: {
          "value": "a",
          "selected": "selected"
        }
      }, [_vm._v("A")]), _c('option', {
        attrs: {
          "value": "b",
          "selected": "selected"
        }
      }, [_vm._v("B")]), _c('option', {
        attrs: {
          "value": "c",
          "selected": "selected"
        }
      }, [_vm._v("C")]), _c('option', {
        attrs: {
          "value": "d",
          "selected": "selected"
        }
      }, [_vm._v("D")]), _c('option', {
        attrs: {
          "value": "e",
          "selected": "selected"
        }
      }, [_vm._v("E")])])])], 1);
    }), _c('b-button', {
      staticStyle: {
        "margin-left": "10px"
      },
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": _vm.addQuestion
      }
    }, [_c('i', {
      staticClass: "fa fa-plus-square"
    }), _vm._v("Tambah Question ")])], 2) : item.field == 'answer_type_id' ? _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.options[item.field],
        expression: "options[item.field]"
      }],
      staticClass: "form-control m-input",
      attrs: {
        "placeholder": 'Enter ' + item.label + ' ...'
      },
      on: {
        "change": function change($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.options, item.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, _vm._l(_vm.list_answer_type, function (item_answer, index_answer) {
      return _c('option', {
        key: item_answer.id,
        domProps: {
          "value": item_answer.id
        }
      }, [_vm._v(_vm._s(item_answer.name))]);
    }), 0) : item.field == 'instructur_id' ? _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.options[item.field],
        expression: "options[item.field]"
      }],
      staticClass: "form-control m-input",
      attrs: {
        "placeholder": 'Enter ' + item.label + ' ...'
      },
      on: {
        "change": function change($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.options, item.field, $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "0",
        "selected": "selected"
      }
    }, [_vm._v("ID Instructur")]), _vm._l(_vm.list_instructur, function (item_instructur, index_instructur) {
      return _c('option', {
        key: item_instructur.id,
        domProps: {
          "value": item_instructur.id
        }
      }, [_vm._v(_vm._s(item_instructur.name))]);
    })], 2) : _c('b-form-input', {
      attrs: {
        "placeholder": 'Enter ' + item.label + ' ...',
        "type": "text",
        "disabled": item.field == 'id' || item.field == 'created_date' || item.field == 'updated_date'
      },
      model: {
        value: _vm.options[item.field],
        callback: function callback($$v) {
          _vm.$set(_vm.options, item.field, $$v);
        },
        expression: "options[item.field]"
      }
    })], 1);
  }), 1)], 1), (_vm.$store.getters['customer/customer'].role_id = 1) ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.submitData
    }
  }, [_c('i', {
    staticClass: "fa fa-dot-circle-o"
  }), _vm._v(" Submit ")]) : _vm._e()], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }